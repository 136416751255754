var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"mb-4"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-form',{ref:"form"},[_c('v-row',{staticStyle:{"background-color":"lightyellow"}},[_c('v-col',{attrs:{"md":"3"}},[_c('v-text-field',{attrs:{"label":"Include keywords","clearable":"","prepend-icon":"add"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1"},on),[_vm._v("help")])]}}])},[_c('span',[_vm._v("Multiple values may be separated by a comma for logical \"AND\" or a pipe for logical \"OR\". Mixing separator types is not permitted. ")])])]},proxy:true}]),model:{value:(_vm.keywordsInclude),callback:function ($$v) {_vm.keywordsInclude=$$v},expression:"keywordsInclude"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('v-text-field',{attrs:{"label":"Ignore keywords","clearable":"","prepend-icon":"remove"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-1"},on),[_vm._v("help")])]}}])},[_c('span',[_vm._v("Multiple values may be separated by a comma. ")])])]},proxy:true}]),model:{value:(_vm.keywordsIgnore),callback:function ($$v) {_vm.keywordsIgnore=$$v},expression:"keywordsIgnore"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.forms_boolFilterVals,"clearable":"","label":"Assigned topic","prepend-icon":"assignment"},model:{value:(_vm.selectedAssignedTopic),callback:function ($$v) {_vm.selectedAssignedTopic=$$v},expression:"selectedAssignedTopic"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.forms_boolFilterVals,"clearable":"","label":"Added","prepend-icon":"assignment"},model:{value:(_vm.selectedAdded),callback:function ($$v) {_vm.selectedAdded=$$v},expression:"selectedAdded"}})],1)],1),_c('v-row',{staticStyle:{"background-color":"lightyellow"}},[_c('v-col',{attrs:{"md":"9"}}),_c('v-col',{attrs:{"md":"3"}},[_c('v-btn',{attrs:{"color":"primary","disabled":_vm.selected.length > 0},on:{"click":_vm.search}},[_vm._v("Search")])],1)],1),_c('hr',{attrs:{"id":"separator"}}),_c('v-row',{staticStyle:{"background-color":"#ffefd5"}},[_c('v-col',[_c('GoogleCampaignsMultiAutoComplete',{attrs:{"accountID":_vm.pAccountID,"mandatory":false},on:{"refreshing":_vm.campaignsRefreshing},model:{value:(_vm.selectedCampaignIDs),callback:function ($$v) {_vm.selectedCampaignIDs=$$v},expression:"selectedCampaignIDs"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"loading":_vm.loadingAdGroups,"items":_vm.topics,"label":"Topic","clearable":"","prepend-icon":"topic"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-dots-vertical")])]}}])},[_c('v-list',[_c('v-list-item-group',[_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){return _vm.refreshAdGroups()}}},[_vm._v("Refresh topics")])],1)],1)],1)],1)]},proxy:true}]),model:{value:(_vm.selectedTopic),callback:function ($$v) {_vm.selectedTopic=$$v},expression:"selectedTopic"}})],1),_c('v-col',[_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","disabled":!_vm.selectedCampaignIDs || !_vm.selectedTopic || _vm.selected.length == 0},on:{"click":_vm.assign}},[_vm._v("Assign")]),_c('v-btn',{staticClass:"mt-2 ml-5",attrs:{"color":"success","disabled":_vm.selected.length == 0,"loading":_vm.adding},on:{"click":_vm.add}},[_vm._v("Add")]),_c('v-btn',{staticClass:"mt-2 ml-5",attrs:{"color":"error","disabled":_vm.selected.length == 0},on:{"click":_vm.reject}},[_vm._v("Reject")])],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"footer-props":{
      'items-per-page-options': _vm.datatables_rowsPerPageItems,
    },"server-items-length":_vm.totalItems,"show-select":"","item-key":"id"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_vm._v(" Loading items.. ")]},proxy:true},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(_vm.datatables_footerPageText(_vm.totalItemsIsEstimate, _vm.estimatedItems, props))+" ")]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }