<template>
  <v-card>
    <v-card-title class="mb-4">
      <v-container fluid class="pa-0">
        <v-form ref="form">

          <v-row style="background-color: lightyellow;">
            <v-col md="3">
              <v-text-field v-model="keywordsInclude" label="Include keywords" clearable prepend-icon="add">
                <template v-slot:append-outer>
                  <v-tooltip>
                    <template v-slot:activator="{ on }">
                      <v-icon class="ml-1" v-on="on">help</v-icon>
                    </template>
                    <span>Multiple values may be separated by a comma for logical "AND" or a pipe for logical "OR". Mixing separator types is not permitted.
                    </span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>

            <v-col md="3">
              <v-text-field v-model="keywordsIgnore" label="Ignore keywords" clearable prepend-icon="remove">
                <template v-slot:append-outer>
                  <v-tooltip>
                    <template v-slot:activator="{ on }">
                      <v-icon class="ml-1" v-on="on">help</v-icon>
                    </template>
                    <span>Multiple values may be separated by a comma.
                    </span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>

            <v-col md="3">
              <v-autocomplete v-model="selectedAssignedTopic" :items="forms_boolFilterVals" clearable label="Assigned topic" 
                prepend-icon="assignment">
              </v-autocomplete>
            </v-col>

            <v-col md="3">
              <v-autocomplete v-model="selectedAdded" :items="forms_boolFilterVals" clearable label="Added" 
                prepend-icon="assignment">
              </v-autocomplete>
            </v-col>
          </v-row>

          <v-row style="background-color: lightyellow;">
            <v-col md="9">
            </v-col>
            <v-col md="3">
              <v-btn color="primary" :disabled="selected.length > 0" @click="search">Search</v-btn>
            </v-col>
          </v-row>

          <hr id="separator">

          <v-row style="background-color: #ffefd5;">
            <v-col>
            <GoogleCampaignsMultiAutoComplete 
              v-model="selectedCampaignIDs"
              :accountID="pAccountID"
              :mandatory="false"
              @refreshing="campaignsRefreshing"
            ></GoogleCampaignsMultiAutoComplete>
            </v-col>

            <v-col>
              <v-autocomplete v-model="selectedTopic" :loading="loadingAdGroups" :items="topics" label="Topic" clearable prepend-icon="topic">
                <template v-slot:append-outer>
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-dots-vertical</v-icon>
                    </template>
                    <v-list>
                      <v-list-item-group>
                        <v-list-item>
                          <v-list-item-title @click="refreshAdGroups()">Refresh topics</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col>
              <v-btn class="mt-2" color="primary" :disabled="!selectedCampaignIDs || !selectedTopic || selected.length == 0" @click="assign">Assign</v-btn>
              <v-btn class="mt-2 ml-5" color="success" :disabled="selected.length == 0" :loading="adding" @click="add">Add</v-btn>
              <v-btn class="mt-2 ml-5" color="error" :disabled="selected.length == 0" @click="reject">Reject</v-btn>
            </v-col>
          </v-row>

        </v-form>
      </v-container>

    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': datatables_rowsPerPageItems,
      }"
      :server-items-length="totalItems"
      class="elevation-1"
      show-select
      v-model="selected"
      item-key="id"
    >
      <template v-slot:loading>
        Loading items..
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import dataTablesMixin from '@/mixins/datatables'
import formsMixin from '@/mixins/forms'

export default {
  name: 'KeywordCollectorTable',

  props: {
    pAccountID: { type: Number, required: true },
    pVerticalID: { type: Number, required: true },
    pCountryID: { type: Number, required: true },
    pLanguageID: { type: Number, required: true },
    updateKey: { type: String, required: true },
  },

  data () {
    return {
      headers: [
        { text: 'Keyword', value: 'keyword' },
        { text: 'Avg searches/m', value: 'avg_monthly_searches' },
        { text: 'Total searches 1y', value: 'total_searches_past_year' },
        { text: 'Comp. name', value: 'competition_name' },
        { text: 'Low CPC', value: 'low_top_of_page_bid' },
        { text: 'High CPC', value: 'high_top_of_page_bid' },
        { text: 'Campaigns', value: 'assigned_campaign_names' },
        { text: 'Topic', value: 'assigned_topic' },
        { text: 'Latest added', value: 'latest_added_at' },
      ],
      items: [],
      selected: [],
      loading: false,
      loadingAdGroups: false,
      adding: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      keywordsInclude: '', // CSV
      keywordsIgnore: '', // CSV
      selectedAssignedTopic: null,
      selectedAdded: null,
      selectedCampaignIDs: [],
      topics: [],
      selectedTopic: null,
      lsOptions: 'keywordCollector_options',
      lsFilters: 'keywordCollector_filters',
      addEndpoint: '/a/core/keyword-ideas/add',
      assignEndpoint: '/a/core/keyword-ideas/assign',
      dataEndpoint: '/a/core/keyword-ideas',
      rejectEndpoint: '/a/core/keyword-ideas/reject',
    }
  },

  mixins: [dataTablesMixin, formsMixin],

  computed: {
    refreshAdGroupsEndpoint () {
      return '/a/google/accounts/' + this.pAccountID + '/refresh-ad-groups'
    },

    topicsEndpoint () {
      return '/a/google/accounts/' + this.pAccountID + '/topics'
    },
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
    pAccountID: {
      handler () {
        this.selected = []
        this.fetchData()
      }
    },
    pLanguageID: {
      handler () {
        this.selected = []
        this.fetchData()
      }
    },
    updateKey: {
      handler () {
        this.fetchData()
      },
    },
  },

  components: {
    GoogleCampaignsMultiAutoComplete: () => import('@/components/autocompletes/GoogleCampaignsMultiAutoComplete.vue'),
  },

  methods: {
    add () {
      if (this.selected.length > 20) {
        alert('A maximum of 20 ideas can be added at the same time')
        return
      }
      this.adding = true
      let saveObj = {
        'ad_network': 'Google',
        'keyword_ideas_ids': this.selected.map(ele => ele.id),
      }
      var body = JSON.stringify(saveObj)
      this.$http.post(this.addEndpoint, body).then(resp => {
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.addEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.adding = false)
    },

    assign () {
      let saveObj = {
        'ad_network': 'Google',
        'account_id': this.pAccountID,
        'keyword_ideas_ids': this.selected.map(ele => ele.id),
        'adwords_campaign_ids': this.selectedCampaignIDs,
        'topic': this.selectedTopic,
      }
      var body = JSON.stringify(saveObj)
      this.$http.put(this.assignEndpoint, body).then(resp => {
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'PUT', url: this.assignEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally()
    },

    campaignsRefreshing () {
      this.selectedCampaignIDs = []
    },

    fetchData: function () {
      if (!this.pAccountID || !this.pLanguageID) {
        this.items = []
        return
      }
      this.selected = []

      this.loading = true
      var myURL = this.dataEndpoint + '?xfields='

      // get fields from headers
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, [], ['id'])

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      // filter account (mandatory)
      myURL += '&account_id=' + this.pAccountID

      // filter language (mandatory)
      myURL += '&languages_fk=' + this.pLanguageID

      // filter keywordsInclude (CSV list of keyword parts to find)
      if (this.keywordsInclude) {
        myURL += this.processKeywordsInclude()
      }

      // filter keywordsIgnore (CSV list of keyword parts to ignore)
      if (this.keywordsIgnore) {

        // trim each value
        var keywordsIgnoreArr = this.keywordsIgnore.split(',')
        keywordsIgnoreArr = keywordsIgnoreArr.map(ele => ele.trim())

        // pass each element to API as a separate param
        keywordsIgnoreArr.forEach(ele => {
          myURL += '&keyword=-~' + ele.trim() + '~'  
        })
      }

      // filter assigned
      if (this.selectedAssignedTopic === 'Yes') {
        myURL += '&assigned_topic={notempty}'
      }
      if (this.selectedAssignedTopic === 'No') {
        myURL += '&assigned_topic={empty}'
      }

      // filter added
      if (this.selectedAdded === 'Yes') {
        myURL += '&latest_added_at={notempty}'
      }
      if (this.selectedAdded === 'No') {
        myURL += '&latest_added_at={empty}'
      }

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    fetchTopics () {
      this.$http.get(this.topicsEndpoint).then(resp => {
        if (resp.data.data) {
          this.topics = resp.data.data
        } else {
          this.topics = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: this.topicsEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally()
    },

    processKeywordsInclude () {
      // return a string to be appended to the fetch URL based on what the user has inputted in the keywordsInclude text box

      // can separate with logical AND (comma) or logical OR (pipe), but not both

      var usesComma = this.keywordsInclude.includes(',')
      var usesPipe = this.keywordsInclude.includes('|')

      // return nothing if user has both
      if (usesComma && usesPipe) {
        return ''
      }

      // return normal filter if user has neither
      if (!usesComma && !usesPipe) {
        return '&keyword=~' + this.keywordsInclude.trim() + '~'
      }

      var keywordsIncludeArr = ''

      // if using AND
      if (usesComma) {

        // split by comma and trim each value
        keywordsIncludeArr = this.keywordsInclude.split(',')
        keywordsIncludeArr = keywordsIncludeArr.map(ele => ele.trim())

        // return each element to API as a separate param
        var ret = ''
        keywordsIncludeArr.forEach(ele => {
          ret += '&keyword=~' + ele + '~'  
        })

        return ret
      }

      // uses OR

      // split by pipe and trim each value
      keywordsIncludeArr = this.keywordsInclude.split('|')
      keywordsIncludeArr = keywordsIncludeArr.map(ele => ele.trim())

      // return with pipe as separator
      return '&keyword=~[' + keywordsIncludeArr.join('|') + ']~'
    },

    refreshAdGroups () {
      this.loadingAdGroups = true

      this.selectedTopic = null

      this.$http.post(this.refreshAdGroupsEndpoint).then(resp => {
        this.fetchTopics()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'POST', url: this.refreshAdGroupsEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loadingAdGroups = false)
    },

    reject () {
      let saveObj = {
        'keyword_ideas_ids': this.selected.map(ele => ele.id),
        'keywords': this.selected.map(ele => ele.keyword),
        'verticals_id': this.pVerticalID,
        'countries_id': this.pCountryID,
        'languages_id': this.pLanguageID,
      }
      var body = JSON.stringify(saveObj)
      this.$http.put(this.rejectEndpoint, body).then(resp => {
        this.fetchData()
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'PUT', url: this.rejectEndpoint, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally()
    },

    search: function () {
      let filterObj = {
        'keywordsInclude': this.keywordsInclude,
        'keywordsIgnore': this.keywordsIgnore,
        'selectedAssignedTopic': this.selectedAssignedTopic,
        'selectedAdded': this.selectedAdded,
      }
      localStorage.setItem(this.lsFilters, JSON.stringify(filterObj))

      this.options.page = 1;
      this.fetchData()
    },
  },

  created: function () {
    // don't refresh data automatically in this table: user will lose selection
    //this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)
    this.fetchTopics()

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }

    // if present, use local filters
    if (localStorage.getItem(this.lsFilters)) {
      let filters = JSON.parse(localStorage.getItem(this.lsFilters))
      if (filters['keywordsInclude']) { this.keywordsInclude = filters['keywordsInclude'] }
      if (filters['keywordsIgnore']) { this.keywordsIgnore = filters['keywordsIgnore'] }
      if (filters['selectedAssignedTopic']) { this.selectedAssignedTopic = filters['selectedAssignedTopic'] }
      if (filters['selectedAdded']) { this.selectedAdded = filters['selectedAdded'] }
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>

<style scoped>
#separator {
  border: 1px solid lightgray;
  background-color: lightgray;
  margin-bottom: 30px;
  margin-top: 30px;
}
</style>